import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { HashRouter as Router, NavLink, Route, useHistory } from 'react-router-dom';
import { apiUrl, PORT } from '../environment/environment';

const Header = () => {
    const token = sessionStorage.getItem('token');
    const isLogin = (token != null) ? true : false;
    const history = useHistory();
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
    const usertype = sessionStorage.getItem('usertype');

    const LogOut = async (e) => {
        e.preventDefault();

        if (usertype === "trainer") {
            const token = sessionStorage.getItem('token');
            if (token) {
                axios.defaults.headers.common['Authorization'] = token;
                axios.get(`${apiUrl}${PORT}/trainer/account/logout`, {}, {
                }).then(function (response) {
                    if (response.data.status === 1) {
                        localStorage.clear();
                        sessionStorage.clear();
                        window.location.href = "/";
                    }
                }).catch(function (error) {
                });
            }
        } else {
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = "/";
        }
    }

    return (
        <>
            <Router>
                <Route path="/mainlogin">{/* <LoginClient></LoginClient> */}</Route>
            </Router>
            <header className="navbar-header">
                <div className="container-fluid">
                    <div className="col-12">
                        <div className="row align-items-center">
                            <div className="col-xl-3 col-lg-4 col-12">
                                <a className="logo-web" href="/">
                                    <img src="/img/KNKTLogo.png" alt="logo" />
                                </a>
                                <button className="custom-toggler navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-controls="collapseExample" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                                    <i className="fas fa-bars"></i>
                                </button>̥
                            </div>
                            <div className="col-xl-9 col-lg-8 col-12 pl-md-0">
                                <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="collapseExample">
                                    <button className="custom-toggler navbar-toggler close_b d-lg-none d-block" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-controls="collapseExample" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                                        <i className="fas fa-times"></i>
                                    </button>
                                    <div className="header-nav">
                                        <div className="row">
                                            <div className="col-lg-9 col-12">
                                                <div className="d-lg-flex">
                                                    <nav className="head-menu">
                                                        <NavLink exact activeClassName="active" to="/">Home</NavLink>
                                                        <NavLink activeClassName="" to="howitworks">How It Works</NavLink>
                                                        <NavLink activeClassName="" to="contactus">Contact</NavLink>
                                                    </nav>
                                                    <nav className="social-media">
                                                        <a target="_blank" href="https://instagram.com/knktfit" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                                                        <a target="_blank" href="http://Facebook.com/knktfit" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                                                        <a target="_blank" href="http://Twitter.com/knktfit" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                                                    </nav>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-12">
                                                {isLogin === true ?
                                                    <>
                                                        <div className='d-flex inline'>
                                                            <button className="main-login border-0 mx-2" onClick={(e) => {
                                                                e.preventDefault();
                                                                if (usertype === 'client') {
                                                                    history.push('/trainer?status=1');
                                                                } else if (usertype === 'trainer') {
                                                                    history.push('/schedulerequest');
                                                                }
                                                                window.location.reload();
                                                            }}>Dashboard</button>
                                                            <button className="main-login border-0" onClick={(e) => LogOut(e)}>
                                                                Log Out
                                                            </button>
                                                        </div>

                                                    </> :

                                                    <>

                                                        <NavLink className="main-login" to='/mainlogin'>
                                                            Main Login
                                                        </NavLink>
                                                    </>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header