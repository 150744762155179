import React from 'react';
import Header from './Header';
import Footer from './Footer';

const HowItWorks = () => {
    console.log("on how it works");
    return (
        <>
            <div className='bgBlack'>
                <Header />
                <section className="goals-block">
                    <div className="container-fluid" /* data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine" */>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="goals-content text-center">
                                        <h1>Meet your goals.</h1>
                                        <h3>Personalized 1-on-1 training at home, work, school… anywhere you want, any time you're ready.</h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <img className="goal-img" src="/img/tanned.jpg" alt="img1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="knkt-form take-training-bg">
                    <div className="container-fluid">
                        <div className="col-12">
                            <div className="knkt-form-content text-center">
                                <div className="row">
                                    <div className="col-12">
                                        <nav className="social-media mb-4">
                                            <a target="_blank" href="https://instagram.com/knktfit" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                                            <a target="_blank" href="http://Facebook.com/knktfit" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                                            <a target="_blank" href="http://Twitter.com/knktfit" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                                        </nav>
                                        <h2>Take your <span className="bottom-bor">training anywhere.</span></h2>
                                        <p>Let's keep you updated on news and updates.</p>
                                    </div>
                                    <div className="col-lg-9 col-12 mx-auto">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-3 col-md-6 col-12 px-md-1">
                                                <input className="input_form" type="text" placeholder="First Name" />
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-12 px-md-1">
                                                <input className="input_form" type="text" placeholder="Last Name" />
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-12 px-md-1">
                                                <input className="input_form" type="email" placeholder="Email Address" />
                                            </div>
                                            <div className="col-lg-2 col-md-6 col-12 px-md-1 text-md-left">
                                                <button className="signup_btn">SIGN UP</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <span className="re-privcy-text text-center">We respect your privacy.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="howtoknkt-block">
                    <div className="container-fluid">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <div className="howtoknkt-content text-center">
                                        <h2>How to <span className="bottom-bor">KNKT Fit.</span></h2>
                                        <p>Make real results happen</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="howknkt-box">
                                        <img src="/img/Start+Screen.png" alt="img" />
                                        <div className="knkt-box-content text-center">
                                            <h3>#1 Train Anywhere</h3>
                                            <p>Create a profile, input your fitness history,</p>
                                            <p>and list your goals. Then press Start Live </p>
                                            <p>Training whenever you're ready.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="howknkt-box">
                                        <img src="/img/Trainer+Details.png" alt="img" />
                                        <div className="knkt-box-content text-center">
                                            <h3>#2 Pick Your Trainer</h3>
                                            <p>Browse our growing roster of expert</p>
                                            <p>trainers. Review their specialities,</p>
                                            <p>recommendations and request to start.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="howknkt-box">
                                        <img src="/img/Live+Training+Screen+1.png" alt="img" />
                                        <div className="knkt-box-content text-center">
                                            <h3>#3 Get to Work</h3>
                                            <p>Select your performance tier, review your</p>
                                            <p>profile with the trainer, and then start your </p>
                                            <p>45 minute training session.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="trusted-block">
                    <div className="container-fluid">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="trusted-content text-center">
                                        <h3>on-demand 1-on-1 training like you've never experienced</h3>
                                        <h2><span className="bottom-bor">Trusted Trainers</span></h2>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <div className="trusted-title">
                                        <h2><span className="bottom-bor font-weight-bold">Built</span> for you</h2>
                                        <h2><span className="bottom-bor font-weight-bold">Motivated</span> for you</h2>
                                        <h2>Just a few <span className="bottom-bor font-weight-bold">clicks away</span></h2>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-12">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-12 px-md-2">
                                            <div className="trusted-trainer-box">
                                                <img src="/img/Trainer+Abby.jpg" alt="img" />
                                                <div className="summary-content">
                                                    <h3><a href="">Certified</a></h3>
                                                    <p>Your trainers are vital to your fitness goals. We make sure they're certified and know what they're doing.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12 px-md-2">
                                            <div className="trusted-trainer-box">
                                                <img src="/img/Trainer+Brian.jpg" alt="img" />
                                                <div className="summary-content">
                                                    <h3><a href="#">Experienced</a></h3>
                                                    <p>Trainers undergo a rigorous application process and our standard baseline is they have to have 2 years of experience.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12 px-md-2">
                                            <div className="trusted-trainer-box">
                                                <img src="/img/Trainer+Logan.jpg" alt="img" />
                                                <div className="summary-content">
                                                    <h3><a href="#">Friendly</a></h3>
                                                    <p>There's nothing like having a trainer who's intimidating and not friendly. All trainers undergo our KNKT Fit review to ensure they're all-around good people.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="limits-block">
                    <div className="container-fluid">
                        <div className="col-12 text-center">
                            <h3>Limits do not exist</h3>
                            <h2 className="p-training-text">Especially with KNKT on your side</h2>
                        </div>
                        <div className="col-xl-5 mx-auto text-center">
                            <div className="appstore">
                                <a className="mr-xl-5 mr-md-4" href="#"><img src="/img/App+Store+Badge.png" alt="playstore" /></a>
                                <a href="#"><img src="/img/google-play-badge.png" alt="playstore" /></a>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default HowItWorks