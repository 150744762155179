import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Collapse } from "react-bootstrap";
import swal from 'sweetalert';
import { apiUrl, PORT } from '../../environment/environment';


function ManageNotifications() {
    const [open, setOpen] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [textNotifications, setTextNotification] = useState(false);
    const [maillinglist, setMaillinglist] = useState(false);
    const [emailNotifications, setEmailNotification] = useState(false);
    const [notifications, setNotifications] = useState(false);
    const setMins = [10, 15, 20, 30, 40, 50];

    useEffect(() => {
        GetList();
    }, []);

    const handleInputs = (e) => {
        let name = e.target.name;
        let allNotifications = {
            app: notifications,
            text: textNotifications,
            email: emailNotifications,
            mailing: maillinglist
        }

        if (name === "emailnotifications") {
            setEmailNotification(!emailNotifications);
            allNotifications.email = !emailNotifications;
        }
        if (name === "maillinglist") {
            setMaillinglist(!maillinglist);
            allNotifications.mailing = !maillinglist;
        }
        if (name === "textnotifications") {
            setTextNotification(!textNotifications);
            allNotifications.text = !textNotifications;
        }
        if (name === "notifications") {
            setNotifications(!notifications);
            allNotifications.app = !notifications;
        }
        updateNotification(allNotifications);
    }
    async function GetList() {
        setIsLoader(true);
        await axios.get(`${apiUrl}${PORT}/trainer/account/getprofile`, {}, {})
            .then(function (response) {
                setIsLoader(false);
                console.log("response", response);
                console.log("response.data.status === 1", response.data.status === 1);
                if (response.data.status === 1) {
                    setTextNotification(response?.data?.result?.textnotifications || textNotifications);
                    setMaillinglist(response?.data?.result?.maillinglist || maillinglist);
                    setEmailNotification(response?.data?.result?.emailnotifications || emailNotifications);
                    setNotifications(response?.data?.result?.notification || notifications);
                } else {
                    swal({
                        title: "Error!",
                        text: response.data.message,
                        icon: "error",
                        button: true
                    });
                }
            }).catch(function (error) {
                setIsLoader(false);
                swal({
                    title: "Error!",
                    text: error,
                    icon: "error",
                    button: true
                });
            });
    };

    async function updateNotification(allNotifications) {
        setIsLoader(true);
        await axios.post(`${apiUrl}${PORT}/trainer/account/updateNotification`, { notification: allNotifications }).then(function (response) {
            setIsLoader(false);
            if (response.data.status === 1) {
                setTextNotification(response?.data?.result?.textnotifications);
                setMaillinglist(response?.data?.result?.maillinglist);
                setEmailNotification(response?.data?.result?.emailnotifications);
                setNotifications(response?.data?.result?.notification);

                swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: true
                });
            }
            else {
                swal({
                    title: "Error!",
                    text: response.data.message,
                    icon: "error",
                    button: true
                });
            }
        }).catch(function (error) {
            setIsLoader(false);
            swal({
                title: "Error!",
                text: error,
                icon: "error",
                button: true
            });
        });
    };
    return (
        <>
            {isLoader &&
                <div className="loading">
                    <div className="mainloader"></div>
                </div>
            }
            <div className="container-fluid">
                <div className="col-md-12 col-12 p-0">
                    <div className="noti_fications">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <h1 className="main_title mb-4">Notifications</h1>
                            </div>

                            <div className='col-6'>
                                <h5 className='w-50'>Web Notifications
                                    <span className="float-right">
                                        <label className="switch">
                                            <input onChange={(e) => handleInputs(e)} name="notifications" type="checkbox" className="default"
                                                checked={notifications !== false ? 'checked' : ''}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </span></h5>
                            </div>
                            <div className='col-6'>
                                <h5 className='w-50'>Text Notifications
                                    <span className="float-right">
                                        <label className="switch">
                                            <input onChange={(e) => handleInputs(e)} name="textnotifications" defaultChecked={textNotifications} type="checkbox" className="default"
                                                checked={textNotifications !== false ? 'checked' : ''}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </span></h5>
                            </div>
                            <div className='col-6 mt-5'>
                                <h5 className='w-50'>Mailling List
                                    <span className="float-right">
                                        <label className="switch">
                                            <input onChange={(e) => handleInputs(e)} name="maillinglist" type="checkbox" className="default"
                                                checked={maillinglist !== false ? 'checked' : ''}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </span></h5>
                            </div>
                            <div className='col-6 mt-5'>
                                <h5 className='w-50'>Email Notification
                                    <span className="float-right">
                                        <label className="switch">
                                            <input onChange={(e) => handleInputs(e)} name="emailnotifications" type="checkbox" className="default"
                                                checked={emailNotifications !== false ? 'checked' : ''}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </span>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default ManageNotifications;
