import axios from "axios";
import { useEffect, useState } from "react";
import { apiUrl, PORT } from "../../environment/environment";
import Moment from "react-moment";
import { Link } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

function ClientPaymentHistory() {
    const [paymentList, setPaymentList] = useState([]);
    useEffect(() => {
        axios.get(`${apiUrl}${PORT}/payment/clientpaymenthistory`)
            .then(response => {
                if (response.status === 200) {
                    response.data.result.reverse();
                    setPaymentList(response.data.result);
                }

            }).catch(function (error) {
            });
    }, []);
    return (
        <>
            <div className="container-fluid">
                <div className="col-md-12 col-12 p-0">
                    <div className="row">
                        <div className="col-md-12 col-12 mb-4">
                            <h1 className="main_title">Payment Histroy</h1>
                            {paymentList.length > 0 ? <>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button btn btn-primary float-right"
                                    table="client_payment_history"
                                    filename="tablexls"
                                    sheet="tablexls"
                                    buttonText="Export to excel" />
                            </> : <></>}

                        </div>
                        <div className="col-md-12 col-12">
                            {paymentList.length > 0 ? <>
                                <div className="history-table table-responsive payment-histroy">
                                    <table className="table" id="client_payment_history">
                                        <thead>
                                            <tr>
                                                {/* <th>Clients</th> */}
                                                <th>Date</th>
                                                <th>Plan type</th>
                                                <th>No of session(s)</th>
                                                <th>Status</th>
                                                <th>Amount</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paymentList.length > 0 ? paymentList.map((element) => {
                                                return (
                                                    <>
                                                        <tr key={`payment_${element}`}>
                                                            <td><Moment format="DD MMMM YYYY, hh:mm A" date={element.createdAt} /></td>
                                                            <td>{element.plantype}</td>
                                                            <td>{element.noofsession === 1 ? 'Single session' : element.noofsession + " sessions"}</td>
                                                            <td><span className="btn-success p-status">Paid</span></td>
                                                            <td>$ {element.amount.toFixed(2)}</td>
                                                            <td><Link className="detail-btn" to={`/sessionpaymentdetail?id=${element._id}`}><i className="fas fa-eye"></i></Link></td>
                                                        </tr>
                                                    </>
                                                )

                                            }) : <><tr><td colSpan="10" className="-text-center"><div className="col-12">
                                                <h4 className="mt-5 text-center">
                                                    <i className="fa fa-exclamation-triangle alerticon"></i>
                                                    No Record Found
                                                </h4>
                                            </div></td></tr></>}
                                        </tbody>
                                    </table>
                                </div>
                            </> : <div className="col-12">
                                <h4 className="no-record-box">
                                    <i className="fa fa-exclamation-triangle alerticon"></i>
                                    No record found!
                                </h4>
                            </div>}

                        </div>
                        {/* <div className="col-md-12 col-12 text-center mt-5">
                            <button className="training_btn w-25 mx-auto">Export Report</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ClientPaymentHistory;
