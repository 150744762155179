import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Moment from 'react-moment';
import { apiUrl, PORT } from '../../environment/environment';

const Allnotifications = () => {
    const [notification, setNotification] = useState([]);
    useEffect(() => {
        axios.get(`${apiUrl}${PORT}/notification/getAll`)
            .then(response => {
                if (response.status == 200) {
                    // const read = notification.filter(x=>x.isRead === '0')
                    response.data.result.reverse();
                    setNotification(response.data.result);
                }
            }).catch(function (error) {
            });
    }, []);

    const markRead = (id) => {
        axios.post(`${apiUrl}${PORT}/notification/markread`, { id: id })
            .then(response => {
            })
            .catch(err => {
            })
    }
    return (
        <>
            <div className="container-fluid">
                <div className="col-md-12 col-12 p-0">
                    <div className="row">
                        <div className="col-md-12 col-12 mb-4">
                            <h1 className="main_title">All Notifications</h1>
                        </div>
                        <div className="col-md-12 col-12">
                            <div className="history-table table-responsive payment-histroy bg-white border">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>DateTime</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {notification.length > 0 ? notification.map((element) => {
                                            return (
                                                <>
                                                    <tr onClick={(e) => { e.preventDefault(); markRead(element._id) }}
                                                        key={`notification_${element}`}
                                                        style={element.isRead === "0" ? { background: "#e8f8fa", borderRadius: "6px", fontWeight: 'bold' } : { background: "#f3f3f3" }}
                                                    >
                                                        <td className="py-4">{element.title}</td>
                                                        <td><Moment format="DD MMMM YYYY, hh:mm A" date={element.createdAt} /></td>
                                                        <td>{element.message}</td>
                                                    </tr>
                                                </>
                                            )

                                        }) : <><tr><td colSpan="10" className="-text-center">No Records found</td></tr></>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* <div className="col-md-12 col-12 text-center mt-5">
                            <button className="training_btn w-25 mx-auto">Export Report</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Allnotifications