import React from 'react'
import { HashRouter as NavLink } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

const ContactUs = () => {
    return (
        <div className='bgBlack'>
            <Header />
            <div className="contactus-block">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <div className="contactus-content">
                                <div className="mb-md-5 pb-3">
                                    <h3>KNKT with us.</h3>
                                    <h1>Let's Talk.</h1>
                                    <p>We're happy to answer questions. Please reach out to our team and we'll get back to you shortly.</p>
                                    <nav className="social-media mb-4 ml-0">
                                        <NavLink activeClassName="ml-md-0 pl-md-0" to="/"><i className="fab fa-instagram"></i></NavLink>
                                        <NavLink activeClassName="" to="/"><i className="fab fa-facebook-f"></i></NavLink>
                                        <NavLink activeClassName="" to="/"><i className="fab fa-twitter"></i></NavLink>
                                    </nav>
                                </div>
                                <h3>Want to be a trainer?</h3>
                                <button className="more-btn">Learn more</button>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="contact-form">
                                <div className="row">
                                    <div className="col-12">
                                        <label>Name *</label>
                                    </div>
                                        <div className="col-lg-6 col-md-12 mb-3 pr-lg-2">
                                        <input className="input-field" type="text" />
                                        <span>First Name</span>
                                    </div>
                                    <div className="col-lg-6 col-md-12 mb-3 pl-lg-2">
                                        <input className="input-field" type="text" />
                                        <span>Last Name</span>
                                    </div>
                                    <div className="col-md-12 col-12 mb-3">
                                        <label>Email *</label>
                                        <input className="input-field" type="Email" />
                                    </div>
                                    <div className="col-md-12 col-12 mb-3">
                                        <label>Subject *</label>
                                        <input className="input-field" type="text" />
                                    </div>
                                    <div className="col-md-12 col-12 mb-4">
                                        <label>Message *</label>
                                        <textarea className="text_area"></textarea>
                                    </div>
                                    <div className="col-12">
                                        <button className="send-btn">Send My Message</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ContactUs