import axios from 'axios';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import { apiUrl, PORT } from '../../environment/environment';
import Swal from 'sweetalert2';

function Videosession() {
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    let mid = query.get("mid");

    const history = useHistory();
    const domain = 'meet.jit.si';
    let api = {};
    // debugger
    // const mid = new URLSearchParams(window.location.search).get("mid");
    // const mid = window.location.href.split('id=')[1];
    var loginUser = {};
    const loginuserrole = localStorage.getItem('usertype');
    const loginuserdetail = localStorage.getItem('user');

    if (loginuserdetail) {
        loginUser = JSON.parse(loginuserdetail);
    }
    const [isMountRender, setMountRender] = useState(true);

    useEffect(() => {
        callToken();
        if (isMountRender) return;
    }, [isMountRender]);

    useEffect(() => {
        setMountRender(false);
        startMeet();
    }, []);

    const callToken = () => {
        var obj = {
            meetingid: mid
        }
        axios.post(`${apiUrl}${PORT}/meeting/getconnectvideosession`, obj, {
        }).then(function (response) {
            // debugger
            if (response.data.status === 1) {
                if (window.location.href.indexOf('calling') === -1) {
                    window.location.replace(window.location.href + '&&calling');
                    window.location.reload()
                    localStorage.setItem("calling", "1");
                }

                if (response.data?.result?.videoSessions?.statusid === 1) {
                    return true;
                } else if (response.data?.result?.videoSessions?.statusid === 2) {
                    window.location.href = (loginuserrole === 'client') ? "/#/rating?id=" + response.data?.result?.videoSessions?.sessionid : "/#/sessiondetails?id=" + response.data?.result?.videoSessions?.sessionid;
                    // history.push(loginuserrole === 'client') ? "/rating?id=" + response.data?.result?.videoSessions?.sessionid : "/sessiondetails?id=" + response.data?.result?.videoSessions?.sessionid;
                } else if (response.data?.result?.videoSessions?.statusid === 3) {
                    window.location.href = (loginuserrole === 'client') ? "/#/trainer?status=1" : "/#/schedulerequest";
                    // history.push(loginuserrole === 'client') ? "/trainer?status=1" : "/schedulerequest";
                }
                else {
                    history.goBack();
                }
            }
            return true;
        }).catch(function (error) {
        });
        // setTimeout(() => {
        //     callToken();
        // }, 3000);
    }

    const endMeeting = async () => {
        var obj = {
            meetingid: mid,
            id: loginUser?._id
        }
        
        await axios.post(`${apiUrl}${PORT}/meeting/endvideosession`, obj, {
        }).then(function (response) {
            console.log("response after video", response);
            if (response.data.status === 1) {
                localStorage.setItem('clientPayment', JSON.stringify(response.data.clientPayment));
                var sesId = response.data?.result?.sessionid;
                if (loginuserrole !== 'client') {
                    // Confirm to fill workout form after VC
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn btn-success',
                            cancelButton: 'btn btn-danger'
                        },
                        buttonsStyling: false
                    })
                    swalWithBootstrapButtons.fire({
                        title: "Fill workout form?",
                        // text: "You won't be able to revert this!",
                        // icon: 'question',
                        showCancelButton: true,
                        cancelButtonText: 'Save for later',
                        confirmButtonText: 'Complete now',
                        reverseButtons: false,
                        confirmButtonClass: "mx-2",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // window.location.href = (loginuserrole === 'client') ? "/#/rating?id=" + sesId : "/#/sessiondetails?id=" + sesId;
                            window.location.href = "/#/sessiondetails?id=" + sesId;
                        } else if (
                            result.dismiss === Swal.DismissReason.cancel
                        ) {
                            window.location.href = "/#/schedulerequest";
                        }
                    })
                } else {
                    window.location.href = "/#/rating?id=" + sesId;
                }



                //window.location.href = (loginuserrole === 'client') ? "/trainer" : "/schedulerequest";
                // window.location.href = (loginuserrole === 'client') ? "/#/rating?id=" + sesId : "/#/sessiondetails?id=" + sesId;
            } else {
                swal({
                    title: "Error!",
                    text: response.data.message,
                    icon: "error",
                    button: true
                })
            }
        }).catch(function (error) {
            //window.alert(error);
        });
    }

    const startMeet = () => {
        const options = {
            roomName: mid,
            width: '100%',
            height: 500,
            configOverwrite: {
                prejoinPageEnabled: false,
                startWithVideoMuted: 2,
                startWithAudioMuted: 2,
                startAudioMuted: 0,
                startVideoMuted: 0
            },
            userInfo: {
                displayName: (loginUser?.firstname || "Guest")
            },
            parentNode: document.querySelector('#jitsi-iframe')
        }
        api = new window.JitsiMeetExternalAPI(domain, options);
        api.addEventListeners({
            participantJoined: function () {
                var plist = api.getParticipantsInfo();
            }
        });

        api.addEventListeners({
            participantLeft: function () {
                $('#jitsi-iframe').empty();
                endMeeting();
            }
        });
    }

    return (
        <>
            <div id="jitsi-iframe"></div>
        </>
    );
}

export default Videosession;