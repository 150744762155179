import React from 'react';
import { HashRouter as NavLink } from 'react-router-dom';

const Footer = () => {
    const token = sessionStorage.getItem('token');
    const isLogin = (token != null) ? true : false;

    // const usertype = sessionStorage.getItem('usertype');
    return (
        <>
            <footer className="footer-bg">
                <div className="container-fluid">
                    <div className="col-12">
                        <div className="row pb-3">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="footer-content">
                                    <p>KNKT FIT <br />
                                        818 W. 7th Street #860 <br />
                                        Los Angeles, CA 90017</p>
                                    <nav className="social-media mb-4">
                                        <a target="_blank" href="https://instagram.com/knktfit" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                                        <a target="_blank" href="http://Facebook.com/knktfit" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                                        <a target="_blank" href="http://Twitter.com/knktfit" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                {isLogin !== true &&
                                    <div className="row pt-md-4">
                                        <div className="col-lg-8 col-md-6 col-12 pr-md-1">
                                            <input className="input_form" type="email" placeholder="Email Address" />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12 px-md-1">
                                            <button className="signup_btn w-100">SIGN UP</button>
                                        </div>
                                        <div className="col-12">
                                            <span className="re-privcy-text text-center text-white">We respect your privacy.</span>
                                        </div>
                                    </div>}

                            </div>
                            <div className="col-lg-4 col-md-12 col-12">
                                <div className="footer-logo">
                                    <a href="/">
                                        <img src="/img/KNKTLogo.png" alt="alt" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            <nav className="footer-nav pt-3"><NavLink to="privacypolicy">Privacy Policy</NavLink></nav>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer