import axios from 'axios';
import { apiUrl, PORT } from '../../environment/environment';

export function verifytokenCall() {
    let status = false;
    const token = localStorage.getItem('token');
    if (token) {
        axios.defaults.headers.common['Authorization'] = token;
        axios.get(`${apiUrl}${PORT}/account/verifytoken`, {}, {
        }).then(function (response) {
            if (response.data.status === 1) {                
                if (status === false && response.data?.result?.User?.videostatus === 1 && response.data?.result?.User?.meetingid !== "") {
                    status = true;
                    window.location.href = ("/#/Incoming?mid=" + response.data?.result?.User?.meetingid);
                }
            }
            return true;
        }).catch(function (error) {
        });
    }
}

export function setCountryMask(country){
    switch(country) {
        case 'us':
          return '(999) 999-9999';
        case 'in':
          return '99999 99999';
        case 'no':
          return '999 99 999';
        default:
          return '99999 99999';
      }
    /* if (country == 'us') {
        return '(999) 999-9999';
    } else if (country == 'in') {
        return '99999 99999';
    } */
}