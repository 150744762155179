import React, { useState } from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
import axios from 'axios';
import { apiUrl, PORT } from './environment/environment';
// import styles from "./public/css/main.css";


const LandingPage = () => {
    const [isLogin, setIsLogin] = useState(false);
    const usertype = sessionStorage.getItem('usertype');
    const token = sessionStorage.getItem('token');
    if (token) {
        axios.defaults.headers.common['Authorization'] = token;
        axios.get(`${apiUrl}${PORT}/account/verifytoken`, {}, {
        }).then(function (response) {
            if (response.data.status === 1) {
                if (usertype === "client")
                    setIsLogin(true);
                else if (usertype === "trainer")
                    setIsLogin(true);
            }
        }).catch(function (error) {
        });
    }
    return (
        <>
            <div className='bgBlack'>
                <Header isLogin={isLogin} />
                <section className="Waitlist-section">
                    <div className="container-fluid" /* data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine" */>
                        <div className="col-12">
                            <div className="row align-item-center">
                                <div className="col-md-5 col-12">
                                    <div className="wait-mob-img">
                                        <img src="/img/Start+Screen+v2.png" alt="w-img" />
                                    </div>
                                </div>
                                <div className="col-md-7 col-12">
                                    <div className="image-title-wrapper">
                                        <p className="sm-title">Say Hi To KNKT FIT</p>
                                        <h4 className="img-title">Find <span className="bottom-bor">live trainers</span> <span className="bottom-bor">whenever</span> and <span className="bottom-bor">wherever</span> you are.</h4>
                                        <p className="wrap-p">Get the training you want on your schedule — <span className="bottom-bor">KNKT</span> is the perfect app for on-demand fitness. <span className="bottom-bor">Always live, never pre-recorded.</span></p>
                                        <p className="sm-title">KNKT fit is currently invite-only</p>
                                        <a href="/" className="wait-listbtn">Join the Waitlist</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="Index-page-content">
                    <div className="container-fulid" /* data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine" */>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <h1>ON-DEMAND PERSONAL TRAINING</h1>
                                </div>
                                <div className="col-md-6 col-12">
                                    <p>As a member, you get our app for storing and keeping track of your fitness profile and full workout history. You also get access to our roster of industry-best trainers and a one-on-one experience unlike any other, wherever you are.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="choose-knkt">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="choose-content" /* data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine" */>
                                    <h3>Choose KNKT FIT</h3>
                                    <h2>REINVENTING THE <span className="bottom-bor">1-ON-1 EXPERIENCE.</span></h2>
                                    <p>Meet your goals anywhere with access to expert instruction.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12" /* data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-sine" */>
                                <div className="choose-block">
                                    <div className="ch-content">
                                        <h2><span className="bottom-bor">Track your fitness</span></h2>
                                        <p>With KNKT FIt you and your trainers are able to record your progress so you never lose track of your goals. Pick up exactly where you left off with a new trainer.</p>
                                    </div>
                                    <div className="ch-content">
                                        <h2><span className="bottom-bor">Tailored personal training</span></h2>
                                        <p>Our expert trainers will work with you to create a custom session based on your goals. Then they'll guide you one-on-one every step of the way.</p>
                                    </div>
                                    <div className="ch-content">
                                        <h2><span className="bottom-bor">Fitness on your schedule!</span></h2>
                                        <p>Only have an hour or less? From night-owls to the office warriors, KNKT Fit allows you to pick up and start training within minutes.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12" /* data-aos="fade-up" data-aos-duration="1500" data-aos-easing="ease-in-sine" */>
                                <div className="choose-img">
                                    <img src="/img/KNKT+iPhone+Hero+Mock+copy.png" alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="p-training-block">
                    <div className="container-fluid" /* data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine" */>
                        <div className="col-12 text-center">
                            <h2 className="p-training-text">Personal training, virtually. <span className="bottom-bor">No pre-recorded content</span>, ever. Download <span className="bottom-bor">KNKT</span> and get fit.</h2>
                        </div>
                        <div className="col-xl-5 mx-auto text-center">
                            <div className="appstore">
                                <a className="mr-xl-5 mr-md-4" href="/"><img src="/img/App+Store+Badge.png" alt="playstore" /></a>
                                <a href="/"><img src="/img/google-play-badge.png" alt="playstore" /></a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="our-mission">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="our-mission-content" /* data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine" */>
                                    <h3>OUR MISSION</h3>
                                    <h2>Providing our members with <span className="bottom-bor">control</span> over their <span className="bottom-bor">schedule</span> and <span className="bottom-bor">fitness experience</span>.</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row" /* data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine" */>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="mission-block">
                                    <h3>Trainers, <span className="bottom-bor font-weight-bold">You Can Trust </span></h3>
                                    <ul className="mission-list">
                                        <li><p>Trainers undergo a rigorous review process</p></li>
                                        <li><p>Min 2 years experience and certified</p></li>
                                        <li><p>Results-oriented not sales oriented</p></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="mission-block">
                                    <h3>Goodbye <span className="bottom-bor font-weight-bold">Scheduling </span></h3>
                                    <ul className="mission-list">
                                        <li><p>Get the flexibility you want</p></li>
                                        <li><p>Start a session 24/7</p></li>
                                        <li><p>Anywhere, from work, school, or home</p></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="mission-block">
                                    <h3>Setup and <span className="bottom-bor font-weight-bold">Train </span></h3>
                                    <ul className="mission-list">
                                        <li><p>All you need is the app and reliable internet</p></li>
                                        <li><p>No equipment? No problem!</p></li>
                                        <li><p>Progress gets saved in your profile</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="interested-tainer-block">
                    <div className="container-fluid" /* data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine" */>
                        <div className="col-12">
                            <div className="interested-tainer-content text-center">
                                <h3>Interested in being a trainer?</h3>
                                <h2>We're looking for talented fit-makers.</h2>
                                <a href="/" className="apply-btn">Apply Today</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="knkt-form">
                    <div className="container-fluid" /* data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine" */>
                        <div className="col-12">
                            <div className="knkt-form-content text-center">
                                <div className="row">
                                    <div className="col-12">
                                        <nav className="social-media mb-4">
                                            <a target="_blank" href="https://instagram.com/knktfit" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                                            <a target="_blank" href="http://Facebook.com/knktfit" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                                            <a target="_blank" href="http://Twitter.com/knktfit" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                                        </nav>
                                        <h2>KNKT to <span className="bottom-bor">your best fit.</span></h2>
                                        <p>We're invite-only so signup to keep up to date.</p>
                                    </div>
                                    <div className="col-lg-9 col-12 mx-auto">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-3 col-md-6 col-12 px-md-1">
                                                <input className="input_form" type="text" placeholder="First Name" />
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-12 px-md-1">
                                                <input className="input_form" type="text" placeholder="Last Name" />
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-12 px-md-1">
                                                <input className="input_form" type="email" placeholder="Email Address" />
                                            </div>
                                            <div className="col-lg-2 col-md-6 col-12 px-md-1 text-md-left">
                                                <button className="signup_btn">SIGN UP</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <span className="re-privcy-text text-center">We respect your privacy.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sqs-layout" /* data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine" */>
                    <div className="row no-gutters" >
                        <div className="col-md">
                            <img className="sqs-img" src="/img/Jane+Profile.jpg" alt="img" />
                        </div>
                        <div className="col-md">
                            <img className="sqs-img" src="/img/Anti-Rotation+2.jpg" alt="img" />
                        </div>
                        <div className="col-md">
                            <img className="sqs-img" src="/img/Squat+Curl+Press+1.jpg" alt="img" />
                        </div>
                        <div className="col-md">
                            <img className="sqs-img" src="/img/Squat+Curl+Press+2.jpg" alt="img" />
                        </div>
                        <div className="col-md">
                            <img className="sqs-img" src="/img/Anti-Rotation+3.jpg" alt="img" />
                        </div>
                        <div className="col-md">
                            <img className="sqs-img" src="/img/Lateral+lunge+T-Spine.jpg" alt="img" />
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default LandingPage